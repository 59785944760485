import CheckCircle from "@/public/icons/CheckCircle.svg";
import Image from "next/image";

export default function CommonInfo({
  icon,
  title,
  description,
  color,
  listInfo,
  image,
  reverse = false,
}) {
  return (
    <div
      className={`${
        reverse ? "flex-row-reverse md:flex-col" : "flex-row md:flex-col"
      } flex justify-center items-center gap-24 lg:gap-12`}
    >
      <div className={"basis-1/2 md:basis-full"}>
        <div className="relative w-12 h-12 mb-5">
          <div className="common-icon">{icon}</div>
          <div
            className={`absolute -top-2 left-2 right-0 w-12 h-12 bg-[${color}] -z-10 rounded-[10px] rotate-[15deg]`}
          ></div>
        </div>
        <h4 className="mb-4 font-semibold font-inter text-display-sm lg:text-display-xs text-dark-new-title">
          {title}
        </h4>
        <p className="text-lg lg:text-md text-dark-new-description">
          {description}
        </p>
        <ul className="pl-4 m-0 mt-8 space-y-5">
          {listInfo.length &&
            listInfo.map((item) => (
              <li className="flex gap-3" key={item}>
                <CheckCircle
                  style={{ color: color }}
                  className={`flex-shrink-0`}
                />
                <p className="text-lg lg:text-md text-dark-new-description">
                  {item}
                </p>
              </li>
            ))}
        </ul>
      </div>
      <Image
        src={image}
        className={"flex-shrink-0 basis-1/2 md:basis-full"}
        alt="CyStack | Fight against cyber threats"
        width={590}
        height={466}
      />
    </div>
  );
}
