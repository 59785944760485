import Head from "next/head";
import { useRouter } from "next/router";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const SEOHead = ({
  seoTitle,
  seoDescription,
  seoImageUrl,
  defaultSeoImageUrl,
}) => {
  const { locale, asPath } = useRouter();
  const url = `${process.env.NEXT_PUBLIC_SITE_URL}${
    locale === "vi" ? "/vi" : ""
  }${asPath}`;

  return (
    <Head>
      <title>{seoTitle}</title>
      <meta name="title" content={seoTitle} />
      <meta name="description" content={seoDescription} />

      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={seoImageUrl || defaultSeoImageUrl} />

      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seoDescription} />
      <meta name="twitter:image" content={seoImageUrl || defaultSeoImageUrl} />
    </Head>
  );
};
export default function DefaultLayout({
  isUsingGoogleCaptcha = true,
  seoTitle = "",
  seoDescription = "",
  seoImageUrl = "",
  children,
}) {
  const defaultSeoImageUrl =
    seoImageUrl || `${process.env.NEXT_PUBLIC_SITE_URL}/ThumbnailCyStack.png`;
  return (
    <main>
      {isUsingGoogleCaptcha ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          scriptProps={{
            async: true,
            defer: true,
            appendTo: "body",
            nonce: undefined,
          }}
        >
          <>
            <SEOHead
              seoTitle={seoTitle}
              seoDescription={seoDescription}
              seoImageUrl={seoImageUrl}
              defaultSeoImageUrl={defaultSeoImageUrl}
            />
            {children}
          </>
        </GoogleReCaptchaProvider>
      ) : (
        <>
          <SEOHead
            seoTitle={seoTitle}
            seoDescription={seoDescription}
            seoImageUrl={seoImageUrl}
            defaultSeoImageUrl={defaultSeoImageUrl}
          />
          {children}
        </>
      )}
    </main>
  );
}
