import "swiper/css";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import ArrowLeft from "@/public/icons/arrow-left.svg";
import ArrowRight from "@/public/icons/arrow-right.svg";
import Shield from "@/public/images/trust-center/icons/Shield.svg";
import Settings from "@/public/images/trust-center/icons/Settings.svg";
import Clock from "@/public/images/trust-center/icons/Clock.svg";
import Lock from "@/public/images/trust-center/icons/Lock.svg";
import CoinsHand from "@/public/images/trust-center/icons/CoinsHand.svg";
import MessageQuestionCircle from "@/public/images/trust-center/icons/MessageQuestionCircle.svg";
import { useState } from "react";
import { useTranslation } from "next-i18next";

export default function TrustCenterFeatures() {
  const { t } = useTranslation("trust-center");

  const [swiper, setSwiper] = useState(null);

  const handleChangeSlide = (direction) => {
    direction === "next" ? swiper.slideNext() : swiper.slidePrev();
  };

  return (
    <section className="py-24 bg-grey-background">
      <div className="new-container">
        <h2 className="mb-6 font-semibold text-center text-display-lg lg:text-display-md text-dark-new-title">
          {t("features.title")}
        </h2>
        <div className="relative mt-16 carousel lg:mt-8">
          <Swiper
            className="relative"
            modules={[Autoplay, Pagination, Navigation]}
            autoHeight={false}
            breakpoints={{
              640: {
                slidesPerView: 1.2,
              },
              768: {
                slidesPerView: 1.8,
              },
              1024: {
                slidesPerView: 2.8,
              },
            }}
            autoplay={{
              delay: 2000,
              pauseOnMouseEnter: true,
            }}
            onSwiper={(s) => {
              setSwiper(s);
            }}
            speed={500}
            loop={true}
            pagination={{
              clickable: true,
            }}
            spaceBetween={24}
          >
            <SwiperSlide className="!h-auto">
              <div className="h-full p-8 space-y-8 transition-shadow bg-white border border-solid lg:p-6 md:p-4 lg:space-y-5 rounded-2xl border-dark-modern-border hover:shadow-lg">
                <div className="w-12 h-12 rounded-[10px] flex items-center justify-center bg-primary-500">
                  <Shield />
                </div>
                <h3 className="text-[28px] leading-9 lg:text-display-xs text-dark-new-title font-semibold">
                  {t("features.item_1.title")}
                </h3>
                <ul className="pl-5 text-lg list-disc text-dark-700">
                  {t("features.item_1.content", { returnObjects: true }).map(
                    (item) => (
                      <li key={item}>{item}</li>
                    )
                  )}
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className="!h-auto">
              <div className="h-full p-8 space-y-8 transition-shadow bg-white border border-solid lg:p-6 md:p-4 lg:space-y-5 rounded-2xl border-dark-modern-border hover:shadow-lg">
                <div className="w-12 h-12 rounded-[10px] flex items-center justify-center bg-primary-500">
                  <Settings />
                </div>
                <h3 className="text-[28px] leading-9 lg:text-display-xs text-dark-new-title font-semibold">
                  {t("features.item_2.title")}
                </h3>
                <ul className="pl-5 text-lg list-disc text-dark-700">
                  {t("features.item_2.content", { returnObjects: true }).map(
                    (item) => (
                      <li key={item}>{item}</li>
                    )
                  )}
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className="!h-auto">
              <div className="h-full p-8 space-y-8 transition-shadow bg-white border border-solid lg:p-6 md:p-4 lg:space-y-5 rounded-2xl border-dark-modern-border hover:shadow-lg">
                <div className="w-12 h-12 rounded-[10px] flex items-center justify-center bg-primary-500">
                  <Clock />
                </div>
                <h3 className="text-[28px] leading-9 lg:text-display-xs text-dark-new-title font-semibold">
                  {t("features.item_3.title")}
                </h3>
                <ul className="pl-5 text-lg list-disc text-dark-700">
                  {t("features.item_3.content", { returnObjects: true }).map(
                    (item) => (
                      <li key={item}>{item}</li>
                    )
                  )}
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className="!h-auto">
              <div className="h-full p-8 space-y-8 transition-shadow bg-white border border-solid lg:p-6 md:p-4 lg:space-y-5 rounded-2xl border-dark-modern-border hover:shadow-lg">
                <div className="w-12 h-12 rounded-[10px] flex items-center justify-center bg-primary-500">
                  <Lock />
                </div>
                <h3 className="text-[28px] leading-9 lg:text-display-xs text-dark-new-title font-semibold">
                  {t("features.item_4.title")}
                </h3>
                <ul className="pl-5 text-lg list-disc text-dark-700">
                  {t("features.item_4.content", { returnObjects: true }).map(
                    (item) => (
                      <li key={item}>{item}</li>
                    )
                  )}
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className="!h-auto">
              <div className="h-full p-8 space-y-8 transition-shadow bg-white border border-solid lg:p-6 md:p-4 lg:space-y-5 rounded-2xl border-dark-modern-border hover:shadow-lg">
                <div className="w-12 h-12 rounded-[10px] flex items-center justify-center bg-primary-500">
                  <CoinsHand />
                </div>
                <h3 className="text-[28px] leading-9 lg:text-display-xs text-dark-new-title font-semibold">
                  {t("features.item_5.title")}
                </h3>
                <ul className="pl-5 text-lg list-disc text-dark-700">
                  {t("features.item_5.content", { returnObjects: true }).map(
                    (item) => (
                      <li key={item}>{item}</li>
                    )
                  )}
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className="!h-auto">
              <div className="h-full p-8 space-y-8 transition-shadow bg-white border border-solid lg:p-6 md:p-4 lg:space-y-5 rounded-2xl border-dark-modern-border hover:shadow-lg">
                <div className="w-12 h-12 rounded-[10px] flex items-center justify-center bg-primary-500">
                  <MessageQuestionCircle />
                </div>
                <h3 className="text-[28px] leading-9 lg:text-display-xs text-dark-new-title font-semibold">
                  {t("features.item_6.title")}
                </h3>
                <ul className="pl-5 text-lg list-disc text-dark-700">
                  {t("features.item_6.content", { returnObjects: true }).map(
                    (item) => (
                      <li key={item}>{item}</li>
                    )
                  )}
                </ul>
              </div>
            </SwiperSlide>
          </Swiper>
          <div
            onClick={() => handleChangeSlide("prev")}
            className="absolute left-0 z-10 flex items-center justify-center border border-solid rounded-full cursor-pointer -bottom-6 border-dark-border-section w-14 h-14"
          >
            <ArrowLeft />
          </div>
          <div
            onClick={() => handleChangeSlide("next")}
            className="absolute right-0 z-10 flex items-center justify-center border border-solid rounded-full cursor-pointer -bottom-6 border-dark-border-section w-14 h-14"
          >
            <ArrowRight />
          </div>
        </div>
      </div>
    </section>
  );
}
