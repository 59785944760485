import { useTranslation } from "next-i18next";
import { Collapse } from "antd";
import Monitor from "@/public/images/trust-center/icons/Monitor.svg";
import Passport from "@/public/images/trust-center/icons/Passport.svg";
import AlertOctagon from "@/public/images/trust-center/icons/AlertOctagon.svg";
import Briefcase from "@/public/images/trust-center/icons/Briefcase.svg";
import { useRouter } from "next/router";

const { Panel } = Collapse;
const CustomHeader = ({ icon, label }) => {
  return (
    <div className="flex items-center gap-5">
      <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-full header-icon">
        {icon}
      </div>
      <span className="inline-block text-dark-title text-[20px] leading-[30px] md:text-[18px] md:leading-7  font-semibold">
        {label}
      </span>
    </div>
  );
};

export default function TrustCenterReason() {
  const { t } = useTranslation("trust-center");
  const router = useRouter();

  return (
    <section className="py-24 trust-center-reason">
      <div className="new-container">
        <h2
          className={`text-display-lg lg:text-display-md text-dark-new-title text-center mx-auto font-semibold ${
            router.locale === "en" ? "max-w-[1023px]" : "max-w-[780px]"
          }`}
        >
          {t("reason.title")}
        </h2>
        <div className="mt-16">
          <Collapse
            ghost
            expandIconPosition="end"
            accordion={true}
            destroyInactivePanel={true}
            defaultActiveKey={["1"]}
          >
            <Panel
              key="1"
              header={
                <CustomHeader
                  icon={<Monitor />}
                  label={t("reason.item_1.title")}
                />
              }
            >
              <p className="text-lg md:text-md font-medium text-dark-title mb-[10px]">
                {t("reason.item_1.content")}
              </p>
              <ul className="pl-5 text-lg md:text-md md:text-[16px] md:leading-6 text-grey mb-6">
                <li>{t("reason.item_1.list_1")}</li>
                <li>{t("reason.item_1.list_2")}</li>
                <li>{t("reason.item_1.list_3")}</li>
              </ul>
            </Panel>
            <Panel
              key="2"
              header={
                <CustomHeader
                  icon={<Passport />}
                  label={t("reason.item_2.title")}
                />
              }
            >
              <p className="text-lg md:text-md text-dark-title">
                {t("reason.item_2.content")}
              </p>
            </Panel>
            <Panel
              key="3"
              header={
                <CustomHeader
                  icon={<AlertOctagon />}
                  label={t("reason.item_3.title")}
                />
              }
            >
              <p className="text-lg md:text-md text-dark-title">
                {t("reason.item_3.content")}
              </p>
            </Panel>
            <Panel
              key="4"
              header={
                <CustomHeader
                  icon={<Briefcase />}
                  label={t("reason.item_4.title")}
                />
              }
            >
              <p className="text-lg md:text-md text-dark-title">
                {t("reason.item_4.content")}
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
    </section>
  );
}
