import { useTranslation } from "next-i18next";
import Download from "@/public/icons/Download.svg";
import { Button } from "antd";
import Image from "next/image";
import { useRouter } from "next/router";

export default function TrustCenterBanner() {
  const { t } = useTranslation("trust-center");
  const router = useRouter();

  return (
    <section className="pt-24">
      <div className="border border-t-0 border-l-0 border-r-0 border-solid new-container border-dark-border-section">
        <div className="mb-16 space-y-6">
          <div className="flex items-center w-fit mx-auto whitespace-nowrap py-1 px-3 border border-solid border-[#C7D7FE] bg-[#EEF4FF] rounded-full gap-[6px]">
            <span className="inline-block w-2 h-2 rounded-full bg-[#6172F3]"></span>
            <span>{t("banner.tag")}</span>
          </div>
          <div className="max-w-[840px] mx-auto space-y-6">
            <h1 className="text-center text-display-xl lg:text-display-lg text-dark-title max-w-[768px] mx-auto">
              {t("banner.title")}
            </h1>
            <p className="text-xl text-center text-dark-new-description lg:text-lg">
              {t("banner.description")}
            </p>
          </div>
          <div className="flex justify-center gap-3 sm:flex-col">
            <a href="https://id.cystack.net/login?SERVICE_URL=%2Ftrust-center%2Fpolicy&SERVICE_SCOPE=whitehub&ENVIRONMENT=&INNER_SERVICE=trustcenter" target="_blank">
              <Button className="bg-[#141414] border-[#141414] h-auto py-[10px] px-[18px] text-md text-white">
                {t("button.start_now", { ns: "common" })}
              </Button>
            </a>
            <Button className="flex bg-white border-[#D0D5DD] items-center gap-3 text-md py-[10px] justify-center">
              <span>{t("button.download_datasheet", { ns: "common" })}</span>
              <Download className="w-5 h-5" />
            </Button>
          </div>
        </div>
        <div className="flex justify-center">
          <Image
            src={`/images/trust-center/ImageBanner-${router.locale}.svg`}
            width={1110}
            height={360}
            className="object-contain max-w-full mx-auto "
            alt="CyStack trust center"
          />
        </div>
      </div>
    </section>
  );
}
