import { Collapse } from "antd";
const { Panel } = Collapse;
import { Trans, useTranslation } from "next-i18next";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";

export function LinkText({ href, children }) {
  return (
    <a href={href || ""} className="text-primary-500 hover:underline">
      {children}
    </a>
  );
}
export default function TrustCenterFrequentlyQuestions() {
  const { t } = useTranslation("trust-center");
  const router = useRouter();

  return (
    <section className="py-16 bg-white trust-center-faq">
      <div className="space-y-24 new-container lg:space-y-16">
        <div className="space-y-6">
          <h2 className="font-semibold text-center text-display-lg lg:text-display-md text-dark-new-title">
            {t("frequently_question.title")}
          </h2>
          <p className="text-xl text-center lg:text-lg text-dark-new-description">
            {t("frequently_question.description")}
          </p>
        </div>
        <div className="max-w-[768px] mx-auto">
          <Collapse
            ghost
            accordion
            expandIconPosition={"start"}
            expandIcon={(p) =>
              p.isActive ? (
                <MinusCircleOutlined
                  style={{
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                    marginTop: 5,
                  }}
                  className="text-[#98A2B3]"
                />
              ) : (
                <PlusCircleOutlined
                  className="text-[#98A2B3]"
                  style={{
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                    marginTop: 5,
                  }}
                />
              )
            }
          >
            <Panel
              header={
                <p className="text-lg font-semibold text-dark-title">
                  {t("frequently_question.item_1.title")}
                </p>
              }
            >
              <div className="pl-5">
                <p className="mb-2 text-md text-dark-new-description">
                  {t("frequently_question.item_1.content")}
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <p className="text-lg font-semibold text-dark-title">
                  {t("frequently_question.item_2.title")}
                </p>
              }
            >
              <div className="pl-5">
                <p className="mb-2 text-md text-dark-new-description">
                  {t("frequently_question.item_2.content")}
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <p className="text-lg font-semibold text-dark-title">
                  {t("frequently_question.item_3.title")}
                </p>
              }
            >
              <div className="pl-5">
                <p className="mb-2 text-md text-dark-new-description">
                  {t("frequently_question.item_3.content")}
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <p className="text-lg font-semibold text-dark-title">
                  {t("frequently_question.item_4.title")}
                </p>
              }
            >
              <div className="pl-5">
                <p className="mb-2 text-md text-dark-new-description">
                  {t("frequently_question.item_4.content_title")}
                </p>
                <p className="mb-2 text-md text-dark-new-description">
                  - {t("frequently_question.item_4.content_1")}
                </p>
                <p className="mb-2 text-md text-dark-new-description">
                  - {t("frequently_question.item_4.content_2")}
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <p className="text-lg font-semibold text-dark-title">
                  {t("frequently_question.item_5.title")}
                </p>
              }
            >
              <div className="pl-5">
                <p className="mb-2 text-md text-dark-new-description">
                  {t("frequently_question.item_5.content")}
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <p className="text-lg font-semibold text-dark-title">
                  {t("frequently_question.item_6.title")}
                </p>
              }
            >
              <div className="pl-5">
                <p className="mb-2 text-md text-dark-new-description">
                  <Trans
                    i18nKey="frequently_question.item_6.content"
                    t={t}
                    components={{
                      Contact: <LinkText href={"/contact-sales"} />,
                    }}
                  />
                </p>
              </div>
            </Panel>
            {router.locale === "vi" ? (
              <Panel
                header={
                  <p className="text-lg font-semibold text-dark-title">
                    {t("frequently_question.item_7.title")}
                  </p>
                }
              >
                <div className="pl-5">
                  <p className="mb-2 text-md text-dark-new-description">
                    <Trans
                      i18nKey="frequently_question.item_7.content"
                      t={t}
                      components={{
                        Pricing: (
                          <LinkText
                            href={`${
                              router.locale === "en" ? "/" : "/vi"
                            }/trust-center/pricing`}
                          />
                        ),
                      }}
                    />
                  </p>
                </div>
              </Panel>
            ) : null}
          </Collapse>
        </div>
      </div>
    </section>
  );
}
