import CommonInfo from "@/components/CommonInfo";
import FileCheck from "@/public/images/trust-center/icons/FileCheck.svg";
import Globe from "@/public/images/trust-center/icons/Globe.svg";
import Monitor from "@/public/images/trust-center/icons/Monitor.svg";
import { Table } from "antd";
import Target from "@/public/images/trust-center/icons/Target.svg";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

const { Column } = Table;
export default function HowTrustCenterSupport() {
  const { t } = useTranslation("trust-center");
  const router = useRouter();

  const listInfo = [
    {
      icon: <Monitor className="text-white" />,
      color: "#1F1FE8",
      title: t("how_trust_center.solutions.item_1.title"),
      listInfo: t("how_trust_center.solutions.item_1.content", {
        returnObjects: true,
      }),
      image: `/images/trust-center/Support1-${router.locale}.svg`,
    },
    {
      icon: <Target className="text-white" />,
      color: "#1F1FE8",
      title: t("how_trust_center.solutions.item_2.title"),
      description: t("how_trust_center.solutions.item_2.content_title"),
      listInfo: t("how_trust_center.solutions.item_2.content", {
        returnObjects: true,
      }),
      image: `/images/trust-center/Support2-${router.locale}.svg`,
    },
    {
      icon: <Globe className="text-white" />,
      color: "#1F1FE8",
      title: t("how_trust_center.solutions.item_3.title"),
      listInfo: t("how_trust_center.solutions.item_3.content", {
        returnObjects: true,
      }),
      image: `/images/trust-center/Support3-${router.locale}.svg`,
    },
  ];

  const dataSource = [
    {
      stage: t("how_trust_center.table.row_1.item_1"),
      business: t("how_trust_center.table.row_1.item_2"),
      solution: (
        <ul className="pl-3 list-disc">
          {t("how_trust_center.table.row_1.item_3", {
            returnObjects: true,
          }).map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      ),
    },
    {
      stage: t("how_trust_center.table.row_2.item_1"),
      business: t("how_trust_center.table.row_2.item_2"),
      solution: (
        <ul className="pl-3 list-disc">
          {t("how_trust_center.table.row_2.item_3", {
            returnObjects: true,
          }).map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      ),
    },
    {
      stage: t("how_trust_center.table.row_3.item_1"),
      business: t("how_trust_center.table.row_3.item_2"),
      solution: (
        <ul className="pl-3 list-disc">
          {t("how_trust_center.table.row_3.item_3", {
            returnObjects: true,
          }).map((item) => (
            <li
              key={item}
              className={`${
                router.locale === "vi" ? "first:list-none first:ml-0 ml-6" : ""
              }`}
            >
              {item}
            </li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <section className="py-24 trust-center-how">
      <div className="space-y-16 new-container lg:space-y-10">
        <div>
          <h2 className="mb-6 font-semibold text-center text-display-lg lg:text-display-md text-dark-new-title">
            {t("how_trust_center.title")}
          </h2>
          <p className="text-xl text-center lg:text-lg text-dark-new-description">
            {t("how_trust_center.description")}
          </p>
        </div>
        <div className="space-y-5">
          <div className="relative w-12 h-12 mb-5">
            <div className="common-icon">
              <FileCheck />
            </div>
            <div
              className={`absolute -top-2 left-2 right-0 w-12 h-12 bg-[#1F1FE8] -z-10 rounded-[10px] rotate-[15deg]`}
            ></div>
          </div>
          <h3 className="text-display-sm text-dark-new-title max-w-[440px] font-inter font-semibold">
            {t("how_trust_center.subtitle")}
          </h3>
        </div>
        <Table
          pagination={false}
          dataSource={dataSource}
          scroll={{ x: "767px" }}
          key={"stage"}
          bordered
        >
          <Column
            title={t("stage")}
            key="stage"
            width={"25%"}
            render={(_, record) => (
              <span className="font-semibold text-dark-title">
                {record.stage}
              </span>
            )}
          />
          <Column
            title={t("business_needs")}
            key="business"
            width={"37.5%"}
            render={(_, record) => record.business}
          />
          <Column
            title={t("solutions")}
            key="solution"
            render={(_, record) => record.solution}
          />
        </Table>
        {listInfo.length &&
          listInfo.map((item, index) => (
            <CommonInfo
              key={index}
              icon={item.icon}
              color={item.color}
              title={item.title}
              description={item.description}
              listInfo={item.listInfo}
              image={item.image}
              reverse={index % 2 === 0}
            />
          ))}
      </div>
    </section>
  );
}
