import { useTranslation } from "next-i18next";
import Image from "next/image";

export default function TrustCenterStandard() {
  const { t } = useTranslation("trust-center");
  return (
    <section className="trust-center-standard common-section-partner bg-[#F9FAFB]">
      <div className="py-24 new-container">
        <h2 className="mb-16 text-center text-dark-new-title text-display-lg">
          {t("support_title")}
        </h2>
        <div className="container relative common-section-partner__list">
          <div className="carousel__wrapper right-to-left flex items-center justify-center h-[144px]">
            {Array.from({ length: 24 }, (_, i) => (i % 12) + 1).map(
              (item, index) => (
                <div key={index * 2} className="carousel__slide">
                  <div className="w-[144px] aspect-1/1 flex items-center justify-center bg-white rounded-xl border border-solid border-dark-modern-border">
                    <Image
                      width={120}
                      height={120}
                      src={`/images/support/logo${item}.svg`}
                      className="object-contain max-h-[120px] max-w-[120px]"
                      alt="CyStack sme support"
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
