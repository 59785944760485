import Image from "next/image";
import { useTranslation } from "next-i18next";

import { useRouter } from "next/router";

export default function TrustCenterImplementationProcess() {
  const { t } = useTranslation("trust-center");
  const router = useRouter();

  return (
    <section className="py-24 lg:py-12 trust-center-implementation-process">
      <h2 className="mb-24 font-semibold text-center lg:mb-12 text-display-lg lg:text-display-md text-dark-new-title">
        {t("implementation_process.title")}
      </h2>
      <div className="flex justify-center">
        <Image
          src={`/images/trust-center/ImplementationProcess-${router.locale}.svg`}
          width={1280}
          height={190}
          className="object-contain max-w-full"
          alt="CyStack trust center"
        />
      </div>
    </section>
  );
}
