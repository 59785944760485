import { useTranslation } from "next-i18next";

import Link from "next/link";
import { useEffect, useMemo } from "react";
import { useRouter } from "next/router";

export default function CommonSubHeader({ listMenu, currentPage, logo }) {
  const { t } = useTranslation("data-leak-detection");
  const router = useRouter();

  const activeMenu = useMemo(() => {
    return router.pathname;
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector("#common-subheader");
      if (window.scrollY > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section id="common-subheader" className="common-subheader md:!static">
        <div className="py-3 new-container">
          <nav className="flex items-center justify-between md:flex-col md:gap-3">
            <Link href={currentPage.link}>
              <div className="flex items-center gap-3 logo">
                {logo}
                <h3 className="inline-block font-semibold cursor-pointer text-display-xs md:text-xl text-dark-new-title">
                  {currentPage.label}
                </h3>
              </div>
            </Link>
            <ul className="flex items-center gap-8 p-0 m-0 list-none lg:gap-4">
              {listMenu.length
                ? listMenu.map((item, index) => (
                    <li key={index} className="py-[6px] px-2">
                      <Link href={item.link} passHref>
                        <a target={item.isExternal ? "_blank" : "_self"}>
                          <span
                            className={`${
                              activeMenu === item.link && "text-primary-500"
                            } inline-block text-[16px] leading-6 lg:text-[15px] lg:leading-5 font-semibold text-[#344054] hover:text-primary-500 hover:underline cursor-pointer`}
                          >
                            {item.label}
                          </span>
                        </a>
                      </Link>
                    </li>
                  ))
                : null}
            </ul>
          </nav>
        </div>
      </section>
    </>
  );
}
