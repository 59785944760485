import Image from "next/image";
import Link from "next/link";
import ArrowRight from "@/public/icons/arrow-right.svg";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";

export default function OnlineSecurityProfile() {
  const { t } = useTranslation("trust-center");
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#demo") {
      const element = document.getElementById("demo");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <section id="demo" className="py-20 lg:py-10">
      <div className="container">
        <div className="bg-cystack-700 rounded-3xl py-16 px-8 text-center flex flex-col justify-center items-center gap-16 lg:gap-8">
          <h2 className="text-display-lg lg:text-display-md font-semibold text-white">
            {t("title_online_security")}
          </h2>
          <div className="w-[456px] sm:max-w-full p-6 rounded-2xl bg-white">
            <img
              src={"/images/trust-center/OnlineSecurityCard.svg"}
              className="max-w-full object-contain mb-5"
            />
            <h4 className="text-[27px] leading-[36px] font-semibold text-dark-new-title text-left mb-1 font-cystack">
              CyStack
            </h4>
            <Link href={"/trust/cystack"}>
              <div className="flex items-center gap-1 text-[#1F1FE8] text-sm font-semibold cursor-pointer hover:underline">
                <span> {t("explore")}</span>
                <ArrowRight />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
