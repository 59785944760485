import { useTranslation } from "next-i18next";
import Building from "@/public/images/trust-center/icons/Building.svg";
import AlertHexagon from "@/public/images/trust-center/icons/AlertHexagon.svg";
import CodeBrowser from "@/public/images/trust-center/icons/CodeBrowser.svg";
import { Collapse } from "antd";
const { Panel } = Collapse;

export default function TrustCenterCommonChallenge() {
  const { t } = useTranslation("trust-center");

  return (
    <section className="py-24 trust-center-common-challenge bg-[#F9FAFB]">
      <div className="new-container">
        <h2 className="text-display-lg lg:text-display-md text-dark-new-title text-center max-w-[950px] mx-auto font-semibold">
          {t("common_challenge.title")}
        </h2>
        <div className="mt-16">
          <Collapse
            ghost
            expandIconPosition={"end"}
            defaultActiveKey={["11", "22", "33"]}
          >
            <Panel
              key={"11"}
              header={
                <div className="flex items-center gap-6">
                  <div className="w-12 h-12 flex-shrink-0 rounded-[10px] flex items-center justify-center bg-primary-500">
                    <Building />
                  </div>
                  <p className="text-[28px] leading-9 lg:text-display-xs md:text-lg font-semibold text-dark-title">
                    {t("common_challenge.item_1.title")}
                  </p>
                </div>
              }
            >
              <div className="pl-14">
                <ul className="pl-5 space-y-3 text-lg list-disc md:text-md text-dark-new-description">
                  <li>{t("common_challenge.item_1.content_1")}</li>
                  <li>{t("common_challenge.item_1.content_2")}</li>
                  <li>{t("common_challenge.item_1.content_2")}</li>
                </ul>
              </div>
            </Panel>
            <Panel
              key={"22"}
              header={
                <div className="flex items-center gap-6">
                  <div className="w-12 h-12 flex-shrink-0 rounded-[10px] flex items-center justify-center bg-primary-500">
                    <AlertHexagon />
                  </div>
                  <p className="text-[28px] leading-9 lg:text-display-xs md:text-lg font-semibold text-dark-title">
                    {t("common_challenge.item_2.title")}
                  </p>
                </div>
              }
            >
              <div className="pl-14">
                <ul className="pl-5 space-y-3 text-lg list-disc md:text-md text-dark-new-description">
                  <li>{t("common_challenge.item_2.content_1")}</li>
                  <li>{t("common_challenge.item_2.content_2")}</li>
                </ul>
              </div>
            </Panel>
            <Panel
              key={"33"}
              header={
                <div className="flex items-center gap-6">
                  <div className="w-12 h-12 flex-shrink-0 rounded-[10px] flex items-center justify-center bg-primary-500">
                    <CodeBrowser />
                  </div>
                  <p className="text-[28px] leading-9 lg:text-display-xs md:text-lg font-semibold text-dark-title">
                    {t("common_challenge.item_3.title")}
                  </p>
                </div>
              }
            >
              <div className="pl-14">
                <p className="text-lg text-dark-new-description">
                  {t("common_challenge.item_3.content_1")}
                </p>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </section>
  );
}
