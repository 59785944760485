import CommonSubHeader from "@/components/CommonSubHeader";
import DefaultLayout from "@/layouts/default";
import HowTrustCenterSupport from "@/components/trust-center/HowTrustCenter";
import Logo from "@/public/images/trust-center/Logo.svg";
import SectionContact from "@/components/SectionContact";
import TrustCenterBanner from "@/components/trust-center/Banner";
import TrustCenterCommonChallenge from "@/components/trust-center/CommonChallenge";
import TrustCenterFeatures from "@/components/trust-center/Features";
import TrustCenterFrequentlyQuestions from "@/components/trust-center/FrequentlyQuestions";
import TrustCenterImplementationProcess from "@/components/trust-center/ImplementationProcess";
import TrustCenterReason from "@/components/trust-center/Reason";
import TrustCenterStandard from "@/components/trust-center/Standard";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import { useRouter } from "next/router";
import OnlineSecurityProfile from "src/components/trust-center/OnlineSecurityProfile";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}
export default function TrustCenter() {
  const { t } = useTranslation("trust-center");
  const currentPage = {
    label: t("title"),
    link: "/trust-center",
  };
  const listMenu = [
    // {
    //   link: "/",
    //   label: t("submenu.datasheet"),
    // },
    {
      link: "/trust-center/pricing",
      label: t("pricing", { ns: "common" }),
    },
  ];
  const router = useRouter();
  useEffect(() => {
    if (router.asPath.includes("#demo")) {
      const demoSection = document.getElementById("demo");
      if (demoSection) {
        setTimeout(() => {
          demoSection.scrollIntoView({ behavior: "smooth" });
        }, [2000]);
      }
    }
  }, [router.asPath]);
  return (
    <DefaultLayout
      seoTitle={t("seo.title")}
      seoDescription={t("seo.description")}
      seoImageUrl={`${process.env.NEXT_PUBLIC_SITE_URL}/images/trust-center/Thumbnail.svg`}
    >
      <CommonSubHeader
        listMenu={listMenu}
        currentPage={currentPage}
        logo={<Logo />}
      />
      <TrustCenterBanner />
      <TrustCenterReason />
      <TrustCenterCommonChallenge />
      <OnlineSecurityProfile />
      <HowTrustCenterSupport />
      <TrustCenterStandard />
      <TrustCenterImplementationProcess />
      <TrustCenterFeatures />
      <TrustCenterFrequentlyQuestions />
      <SectionContact />
    </DefaultLayout>
  );
}
